import { Box, Stack, Typography } from '@mui/material'
import { useEpiCustomDictionary } from '@trr/app-shell-data'

import { IEvaluation } from '../../../types/IEvaluation.types'

import EvaluationButton from './EvaluationButton'

interface IEvaluationButtonSectionProps {
  label: string
  evaluations: IEvaluation[]
  reflections: {
    evaluationId: string
    reflections: {
      id: string
      value: string
    }[]
  }[]
}

export const EvaluationButtonSection = ({
  label,
  evaluations,
  reflections,
}: IEvaluationButtonSectionProps) => {
  const epi = useEpiCustomDictionary(['reflect'])
  return (
    <Box>
      <span>
        <Typography gutterBottom variant="body1" component="h3">
          <b>
            {label} {epi('points')}
          </b>
          {label === '1' && `- ${epi('noPriorityText')}`}
          {label === '6' && `- ${epi('fullPriorityText')} `}
        </Typography>
      </span>

      <Stack flexWrap={'wrap'} direction={'row'} gap={2}>
        {evaluations.map((v) => (
          <EvaluationButton
            disabled={reflections.length > 2}
            key={v.id}
            evaluation={v}
          />
        ))}
      </Stack>
    </Box>
  )
}

export default EvaluationButtonSection
