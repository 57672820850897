import { useEpiContent } from '@trr/app-shell-data'

import { Stack, Typography, useTheme } from '@mui/material'

import { IILivslinjenContent } from '../../../App/App.types'
import EpiImage from '../../../Components/EpiImage/EpiImage'
import useDeviceSize from '../../../utils/hooks/useDeviceSize'

const MilestoneHeader = () => {
  const { header } = useEpiContent<IILivslinjenContent>()
  const { smallScreensUp } = useDeviceSize()
  const { palette } = useTheme()
  if (!header) return null

  return (
    <Stack
      justifyContent="space-between"
      alignItems="center"
      sx={{
        backgroundColor: palette.surface?.purple,
        px: 6,
        py: smallScreensUp ? 0 : 2,
      }}
      direction="row"
    >
      <Stack direction="column">
        <Typography variant="body1" fontWeight="bold">
          {header.text}
        </Typography>
      </Stack>
      {smallScreensUp && <EpiImage width="350px" url={header.imageUrl} />}
    </Stack>
  )
}

export default MilestoneHeader
