/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react'
import {
  Box,
  Button,
  Collapse,
  Divider,
  Stack,
  Typography,
} from '@mui/material'
import { TransitionGroup } from 'react-transition-group'
import { RestartAlt, Shortcut, Undo } from '@mui/icons-material'
import { useEpiCustomDictionary } from '@trr/app-shell-data'
import { useDispatch } from 'react-redux'
import { useTrackCustomClick } from '@trr/gtm-tracking'

import { IEvaluation } from '../../../types/IEvaluation.types'
import { usePostEvaluationMutation } from '../../../utils/network/kompetensApi'
import { openDeleteModal } from '../evaluationDeleteSlice'
import useDeviceSize from '../../../utils/hooks/useDeviceSize'

import EvaluationCard from './EvaluationCard'
import EvaluationProgress from './EvaluationProgress'
import EvaluatedModal from './EvaluatedModal'

interface IEvaluateProps {
  evaluations: IEvaluation[]
  activeIndex: number
  isEvaluating: (v: boolean) => void
  onEvaluated: () => void
}

const Evaluate = ({
  evaluations,
  activeIndex,
  onEvaluated,
  isEvaluating,
}: IEvaluateProps) => {
  const trackCustomClick = useTrackCustomClick()
  const [showEvaluatedModal, setShowEvaluatedModal] = useState(false)
  const [evaluate, { isLoading }] = usePostEvaluationMutation()
  const dispatch = useDispatch()
  const onRegretClick = () => {
    evaluate({
      id: (activeIndex - 1).toString(),
      value: 0,
    }).catch((e: unknown) => {
      console.log(e)
    })
  }

  const amountLeft = evaluations.length - activeIndex
  const { mediumScreenDown } = useDeviceSize()

  useEffect(() => {
    if (amountLeft === evaluations.length - 1) {
      trackCustomClick('varderingskompassen', { label: 'Påbörjad' })
    }
    if (evaluations && amountLeft === 0) {
      setShowEvaluatedModal(true)
    } else isEvaluating(true)
  }, [amountLeft, evaluations])

  const epi = useEpiCustomDictionary(['evaluate'])
  return (
    <>
      <EvaluatedModal
        open={showEvaluatedModal}
        onClose={() => {
          setShowEvaluatedModal(false)
        }}
        onProceedClick={onEvaluated}
      />
      <Box>
        <Typography variant="h6" component="h2">
          {epi('header')}
        </Typography>
        <Typography variant="body1">{epi('ingress')}</Typography>
        <Stack
          justifyContent={mediumScreenDown ? 'center' : 'normal'}
          alignItems="center"
          gap={2}
          sx={{ my: 4, position: 'relative' }}
          direction={mediumScreenDown ? 'column-reverse' : 'row'}
        >
          {mediumScreenDown && evaluations[activeIndex] ? (
            <EvaluationCard
              id={evaluations[activeIndex].id}
              label={evaluations[activeIndex].term}
              description={evaluations[activeIndex].description}
              inFocus
            />
          ) : (
            <TransitionGroup component={null}>
              {evaluations.map((e) => {
                if (
                  parseInt(e.id) === activeIndex ||
                  parseInt(e.id) === activeIndex + 1
                )
                  return (
                    <Collapse orientation="horizontal" key={e.id}>
                      <EvaluationCard
                        inFocus={parseInt(e.id) === activeIndex}
                        id={e.id}
                        label={e.term}
                        description={e.description}
                      />
                    </Collapse>
                  )
              })}
            </TransitionGroup>
          )}

          <Stack
            justifyContent={mediumScreenDown ? 'center' : 'normal'}
            alignItems="center"
            direction="row"
            sx={{
              position:
                amountLeft < 1 || mediumScreenDown ? 'unset' : 'absolute',
              left: '450px',
              backgroundImage: `linear-gradient( 90deg, rgba(255,255,255,0) 0%, white 10%)  `,
              width: '430px',
              height: '250px',
              zIndex: 3,
            }}
          >
            {amountLeft > 0 && !mediumScreenDown && (
              <Divider sx={{ ml: '34px' }} orientation="vertical" />
            )}
            <Box bgcolor="white" sx={{ md: { ml: 5 } }}>
              <EvaluationProgress
                percentage={100 - 100 * (1 - activeIndex / evaluations.length)}
                amountLeft={amountLeft}
              />
            </Box>
          </Stack>
        </Stack>
        <Stack
          gap={1}
          alignItems={mediumScreenDown ? 'center' : 'unset'}
          direction={mediumScreenDown ? 'column' : 'row'}
        >
          {activeIndex > 0 && (
            <>
              <Button
                disabled={isLoading}
                size="small"
                onClick={onRegretClick}
                startIcon={<Undo />}
                variant="text"
                sx={{ maxWidth: 'fit-content' }}
              >
                {epi('regretPreviousButtonLabel')}
              </Button>

              <Button
                size="small"
                onClick={() => dispatch(openDeleteModal())}
                startIcon={<RestartAlt />}
                variant="text"
                sx={{ maxWidth: 'fit-content' }}
              >
                {epi('resetButtonLabel')}
              </Button>
              {amountLeft < 1 && (
                <Button
                  disabled={isLoading}
                  size="small"
                  onClick={() => {
                    onEvaluated()
                  }}
                  startIcon={<Shortcut />}
                  variant="text"
                  sx={{ maxWidth: 'fit-content' }}
                >
                  {epi('proceedButtonLabel')}
                </Button>
              )}
            </>
          )}
        </Stack>
      </Box>
    </>
  )
}

export default Evaluate
