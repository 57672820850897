import { createTheme, ThemeProvider } from '@mui/material'
import { defaultLocale, themeOptions } from '@trr/mui-theme'
import {
  type AppShellDataProps,
  AppShellDataProvider,
} from '@trr/app-shell-data'
import { Provider } from 'react-redux'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3'
import { sv } from 'date-fns/locale'
import { GTMTracker } from '@trr/gtm-tracking'

import Oversikt from '../Features/Oversikt/Oversikt'
import MainContainer from '../Components/MainContainer/MainContainer'
import { AppShellRouter, KeyRoute } from '../utils/router'
import SimplePageTemplate from '../Features/SimplePageTemplate/SimplePageTemplate'
import PersonligSpegling from '../Features/PersonligSpegling/PersonligSpegling'
import Jobmatch from '../Features/Jobmatch/Jobmatch'
import { store } from '../utils/store/configureStore'
import Utbildningar from '../Features/Utbildningar/Utbildningar'
import Yrkesprognoser from '../Features/Yrkesprognoser/Yrkesprognoser'
import Varderingskompassen from '../Features/Varderingskompassen/Varderingskompassen'
import Karriarsokning from '../Features/Karriarsokning/Karriarsokning'
import Framtidsmal from '../Features/Framtidsmal/Framtidsmal'
import Livslinjen from '../Features/Livslinjen/Livslinjen'
import SystemFeedback from '../Features/SystemFeedback/SystemFeedback'

const App = ({ appShellData }: { appShellData: AppShellDataProps }) => {
  const theme = createTheme(themeOptions, defaultLocale)
  return (
    <AppShellDataProvider value={appShellData}>
      <ThemeProvider theme={theme}>
        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={sv}>
          <Provider store={store}>
            <GTMTracker mfName="karriarvagledning">
              <MainContainer>
                <SystemFeedback />
                <AppShellRouter currentKey={appShellData.currentKey}>
                  <KeyRoute urlKey="oversikt">
                    <Oversikt />
                  </KeyRoute>
                  <KeyRoute urlKey="livslinjen">
                    <SimplePageTemplate
                      customContent={<Livslinjen />}
                      identifier={'livslinjen'}
                    />
                  </KeyRoute>
                  <KeyRoute urlKey="jmt">
                    <SimplePageTemplate
                      customContent={<Jobmatch />}
                      identifier={'jmt'}
                    />
                  </KeyRoute>
                  <KeyRoute urlKey="yrkesprognoser">
                    <SimplePageTemplate
                      customContent={<Yrkesprognoser />}
                      identifier={'yrkesprognoser'}
                    />
                  </KeyRoute>
                  <KeyRoute urlKey="utbildningar">
                    <SimplePageTemplate
                      customContent={<Utbildningar />}
                      identifier={'utbildningar'}
                    />
                  </KeyRoute>
                  <KeyRoute urlKey="karriarsokning">
                    <SimplePageTemplate
                      customContent={<Karriarsokning />}
                      identifier={'karriarsokning'}
                    />
                  </KeyRoute>
                  <KeyRoute urlKey="personligspegling">
                    <SimplePageTemplate
                      customContent={<PersonligSpegling />}
                      identifier={'personligSpegling'}
                    />
                  </KeyRoute>
                  <KeyRoute urlKey="varderingskompassen">
                    <SimplePageTemplate
                      customContent={<Varderingskompassen />}
                      identifier={'varderingskompassen'}
                    />
                  </KeyRoute>
                  <KeyRoute urlKey="framtidsmal">
                    <SimplePageTemplate
                      customContent={<Framtidsmal />}
                      identifier={'framtidsmal'}
                    />
                  </KeyRoute>
                </AppShellRouter>
              </MainContainer>
            </GTMTracker>
          </Provider>
        </LocalizationProvider>
      </ThemeProvider>
    </AppShellDataProvider>
  )
}

export default App
