import { Box, Stack, Typography, Button, useTheme } from '@mui/material'
import { useEpiContent, useEpiCustomDictionary } from '@trr/app-shell-data'
import { useEffect, useState } from 'react'
import { useTrackCustomClick } from '@trr/gtm-tracking'

import { IEditorialBlockContent } from '../../../App/App.types'
import { useGetFramtidsmalQuery } from '../../../utils/network/kompetensApi'

import FramtidsmalForm from './FramtidsmalForm'

export interface IFramtidsmalContent {
  [key: string]: unknown
  karriarmal: IEditorialBlockContent
  studiemal: IEditorialBlockContent
  forvantningar: IEditorialBlockContent
}

const FramtidsmalView = () => {
  const trackCustomClick = useTrackCustomClick()
  const [isEditing, setIsEditing] = useState(false)
  const { forvantningar } = useEpiContent<IFramtidsmalContent>()
  const epi = useEpiCustomDictionary(['innehall'])
  const { palette } = useTheme()
  const { data, isLoading, error } = useGetFramtidsmalQuery()
  const handleEditClicked = () => {
    setIsEditing(true)
  }
  const handleCancelClicked = () => {
    setIsEditing(false)
  }

  const handleSaveClicked = () => {
    setIsEditing(false)
  }

  useEffect(() => {
    if (
      (data?.careerGoals?.trim() && data.careerGoals.trim().length > 0) ||
      (data?.studyGoals?.trim() && data.studyGoals.trim().length > 0) ||
      (data?.expectations?.trim() && data.expectations.trim().length > 0)
    ) {
      trackCustomClick('framtidsmal', { label: 'Påbörjad' })
    }
    if (
      data?.careerGoals?.trim() &&
      data.careerGoals.trim().length > 0 &&
      data.studyGoals?.trim() &&
      data.studyGoals.trim().length > 0 &&
      data.expectations?.trim() &&
      data.expectations.trim().length > 0
    ) {
      trackCustomClick('framtidsmal', { label: 'Genomförd' })
      trackCustomClick('framtidsmal', { label: 'Genomförd med reflektion' })
    }
  }, [data, trackCustomClick])

  if (error)
    return (
      <Typography sx={{ mt: 2 }} variant="h6" component="p">
        {epi('getFramtidsmalError')}
      </Typography>
    )
  if (isLoading) return null
  return (
    <>
      {isEditing ||
      !data ||
      (!data.careerGoals && !data.studyGoals && !data.expectations) ? (
        <FramtidsmalForm
          framtidsmal={data}
          onSubmit={handleSaveClicked}
          onCancel={handleCancelClicked}
        />
      ) : (
        <Stack>
          <Typography
            variant="h6"
            component="h3"
            sx={{
              mt: 3,
              mb: 1,
            }}
          >
            {epi('carrerGoals')}
          </Typography>
          {data.careerGoals?.trim() && data.careerGoals.trim().length > 0 ? (
            <Typography whiteSpace={'break-spaces'} variant="body1">
              {data.careerGoals.trim()}
            </Typography>
          ) : (
            <Typography
              whiteSpace={'break-spaces'}
              variant="body1"
              fontStyle="italic"
              color={palette.grey[700]}
            >
              {epi('careerGoalsPlaceholder')}
            </Typography>
          )}
          <Typography
            variant="h6"
            component="h3"
            sx={{
              mt: 3,
              mb: 1,
            }}
          >
            {epi('studyGoals')}
          </Typography>
          {data.studyGoals?.trim() && data.studyGoals.trim().length > 0 ? (
            <Typography whiteSpace={'break-spaces'} variant="body1">
              {data.studyGoals.trim()}
            </Typography>
          ) : (
            <Typography
              whiteSpace={'break-spaces'}
              variant="body1"
              fontStyle="italic"
              color={palette.grey[700]}
            >
              {epi('studyGoalsPlaceholder')}
            </Typography>
          )}
          <Box
            sx={{
              bgcolor: palette.surface?.yellow,
              fontSize: '16px',
              p: 4,
              mt: 3,
              mb: 2,
            }}
          >
            <Typography
              variant="h6"
              component="h3"
              sx={{
                mt: 1,
                mb: 1,
              }}
            >
              {forvantningar.name}
            </Typography>
            {data.expectations?.trim() &&
            data.expectations.trim().length > 0 ? (
              <Typography whiteSpace={'break-spaces'} variant="body1">
                {data.expectations.trim()}
              </Typography>
            ) : (
              <Typography
                whiteSpace={'break-spaces'}
                variant="body1"
                fontStyle="italic"
                color={palette.grey[700]}
              >
                {epi('expectationsPlaceholder')}
              </Typography>
            )}
          </Box>
          <Box>
            <Button
              data-testid="edit-button"
              variant="text"
              size="medium"
              onClick={handleEditClicked}
            >
              {epi('edit')}
            </Button>
          </Box>
        </Stack>
      )}
    </>
  )
}
export default FramtidsmalView
