import { Box, Stack, Typography, useTheme } from '@mui/material'
import { useEpiCustomDictionary } from '@trr/app-shell-data'

import ProgressCircle from './ProgressCircle'

interface IEvaluationProgressProps {
  percentage: number
  amountLeft: number
}

const EvaluationProgress = ({
  percentage,
  amountLeft,
}: IEvaluationProgressProps) => {
  const { palette } = useTheme()
  const epi = useEpiCustomDictionary(['evaluate'])
  return (
    <Box sx={{ position: 'relative' }}>
      <ProgressCircle
        percentage={100 - percentage}
        color={palette.secondary.main}
        secondaryColor={palette.secondary.light}
      />
      <Stack
        sx={{
          position: 'absolute',
          maxWidth: '100px',
          top: '50px',
          left: '50px',
        }}
        alignItems="center"
      >
        <Typography sx={{ textAlign: 'center' }} variant="h2" component="p">
          {amountLeft > 0 && amountLeft}
        </Typography>
        <Typography
          sx={{ textAlign: 'center' }}
          variant="subtitle2"
          component="p"
        >
          {amountLeft > 0 &&
            (amountLeft === 1
              ? epi('evaluationLeftText')
              : epi('evaluationsLeftText'))}
        </Typography>
      </Stack>
    </Box>
  )
}
export default EvaluationProgress
