import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material'
import { useEpiCustomDictionary } from '@trr/app-shell-data'

import { useDeleteEvaluationReflectionMutation } from '../../../utils/network/kompetensApi'

interface IDeleteReflectionModalProps {
  setOpen: (v: boolean) => void
  open: boolean
  id: string
  onDeleteClick: (v: string) => void
}

const DeleteReflectionModal = ({
  setOpen,
  open,
  id,
  onDeleteClick,
}: IDeleteReflectionModalProps) => {
  const epi = useEpiCustomDictionary(['deleteReflectionModal'])
  const [, { isLoading }] = useDeleteEvaluationReflectionMutation()
  const handleSaveClick = () => {
    onDeleteClick(id)
  }
  if (!open) return null
  return (
    <Dialog
      scroll="paper"
      fullWidth
      open={open}
      onClick={(event) => {
        event.stopPropagation()
      }}
      onClose={() => {
        setOpen(false)
      }}
      aria-labelledby="delete-reflection-title"
    >
      <DialogTitle id="delete-reflection-title">{epi('header')}</DialogTitle>
      <DialogContent>
        <DialogContentText sx={{ mb: 3 }}>{epi('ingress')}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          variant="text"
          onClick={() => {
            setOpen(false)
          }}
        >
          {epi('cancelButtonLabel')}
        </Button>
        <Button
          data-testid="deleteEvaluationButton"
          disabled={isLoading}
          onClick={handleSaveClick}
          variant="text"
        >
          {epi('confirmButtonLabel')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default DeleteReflectionModal
