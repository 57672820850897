import { Card, Stack, Typography, useTheme } from '@mui/material'

import { useEpiContent } from '@trr/app-shell-data'

import { IContactPerson } from '../../../types/IContactPerson.types'
import EpiImage from '../../../Components/EpiImage/EpiImage'
import { IPersonligSpeglingContent } from '../../../App/App.types'
import useDeviceSize from '../../../utils/hooks/useDeviceSize'

import PersonCard from './PersonCard'

interface IPersonsListProps {
  persons: IContactPerson[]
}
const PersonsList = ({ persons }: IPersonsListProps) => {
  const { palette } = useTheme()

  const { smallScreensUp } = useDeviceSize()
  const { addContact } = useEpiContent<IPersonligSpeglingContent>()

  if (persons.length < 1)
    return (
      <Stack
        alignItems="center"
        sx={{
          p: 3,
          backgroundColor: palette.surface?.purple,
          mt: smallScreensUp ? 3 : 0,
        }}
        justifyContent="space-between"
        direction="row"
      >
        <Typography fontWeight="bold" variant="body1">
          {addContact.text}
        </Typography>
        {addContact.imageUrl && (
          <EpiImage
            width={smallScreensUp ? '300' : '150'}
            url={addContact.imageUrl}
          />
        )}
      </Stack>
    )
  return (
    <Stack gap={3}>
      {persons.map((p: IContactPerson) => (
        <Card key={p.id}>
          <PersonCard person={p} />
        </Card>
      ))}
    </Stack>
  )
}

export default PersonsList
