import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import { useState } from 'react'
import { useEpiContent } from '@trr/app-shell-data'
import { useDispatch, useSelector } from 'react-redux'
import { Controller, useForm } from 'react-hook-form'
import { any, isEmpty } from 'ramda'
import { useTrackCustomClick } from '@trr/gtm-tracking'

import { closeModal } from '../evaluationReflectionSlice'
import CancelFormDialog from '../../../Components/CancelFormDialog/CancelFormDialog'
import { IVarderingskompassenContent } from '../../../App/App.types'
import {
  useDeleteEvaluationReflectionMutation,
  useGetEvaluationQuery,
  usePostEvaluationReflectionMutation,
} from '../../../utils/network/kompetensApi'
import { RootState } from '../../../utils/store/configureStore'
import { getQuestionsFromContent } from '../../../utils/Helpers/getQuestionsFromContent'
import { useCombineEpiCustomDictionary } from '../../../utils/hooks/useCombineEpiCustomDictionary'

interface IEvaluationReflectionFormProps {
  defaultValues: { [key: string]: string }
}

const EvaluationReflectionForm = ({
  defaultValues,
}: IEvaluationReflectionFormProps) => {
  const [cancelFormOpen, setCancelFormOpen] = useState(false)
  const epi = useCombineEpiCustomDictionary(['reflect', 'inputValidationTexts'])
  const { data: evaluationData } = useGetEvaluationQuery()
  const trackCustomClick = useTrackCustomClick()

  const { evaluationReflectionModal } =
    useEpiContent<IVarderingskompassenContent>()
  const [reflect, { isLoading: isUpdatingEvaluationReflection }] =
    usePostEvaluationReflectionMutation()
  const [deleteReflection, { isLoading: isDeletingEvaluationReflection }] =
    useDeleteEvaluationReflectionMutation()

  const isLoading =
    isUpdatingEvaluationReflection || isDeletingEvaluationReflection
  const value = useSelector(
    (state: RootState) => state.evaluationReflection.value
  )

  const dispatch = useDispatch()

  const onSubmit = (data: { [key: string]: string }) => {
    const reflections = [] as { id: string; value: string }[]
    Object.entries(data).forEach((e) =>
      reflections.push({ id: e[0], value: e[1] })
    )
    if (any((r) => !isEmpty(r.value) && r.value !== null, reflections)) {
      reflect({
        evaluationId: parseInt(value),
        reflections: reflections,
      })
        .unwrap()
        .then(() => {
          reset()
          dispatch(closeModal())
        })
        .catch(() => {
          console.log('error updating reflection')
        })
    } else {
      deleteReflection(value)
        .unwrap()
        .then(() => {
          reset()
          dispatch(closeModal())
        })
        .catch(() => {
          console.log('error deleting reflection')
        })
    }

    // Track in GA
    if (evaluationData?.reflections.length === 2) {
      trackCustomClick('varderingskompassen', { label: 'Genomförd' })
    }
  }

  const onCancelClick = () => {
    if (isDirty) setCancelFormOpen(true)
    else dispatch(closeModal())
  }

  const questions = evaluationReflectionModal
    ? getQuestionsFromContent(evaluationReflectionModal)
    : []

  const {
    handleSubmit,
    reset,
    control,
    formState: { errors, isDirty },
  } = useForm({ defaultValues })

  return (
    <>
      <DialogContent dividers>
        <CancelFormDialog
          open={cancelFormOpen}
          setOpen={setCancelFormOpen}
          onCancel={() => {
            setCancelFormOpen(false)
            dispatch(closeModal())
          }}
        />
        <form
          data-testid="reflect-form"
          onSubmit={(event) => void handleSubmit(onSubmit)(event)}
        >
          <Stack gap={3}>
            {questions.map((q) => (
              <Box key={q.identifier}>
                <Typography
                  gutterBottom
                  variant="body1"
                  component="label"
                  fontWeight="bold"
                  htmlFor={q.identifier}
                >
                  {q.label}
                </Typography>

                <Controller
                  name={q.identifier}
                  control={control}
                  rules={{
                    maxLength: {
                      value: 2000,
                      message: epi('maxLength2000ErrorMessage'),
                    },
                  }}
                  render={({ field }) => (
                    <TextField
                      id={q.identifier}
                      inputProps={{
                        'data-testid': q.identifier,
                        'aria-label': q.label,
                      }}
                      error={!!errors[q.identifier]}
                      fullWidth
                      sx={{ background: 'white', mt: 1 }}
                      multiline
                      rows={5}
                      value={field.value}
                      onChange={field.onChange}
                      helperText={
                        errors[q.identifier]
                          ? (errors[q.identifier]?.message as string)
                          : ''
                      }
                    />
                  )}
                />
              </Box>
            ))}
          </Stack>
        </form>
      </DialogContent>
      <DialogActions>
        <Button disabled={isLoading} variant="text" onClick={onCancelClick}>
          {epi('cancelButtonLabel')}
        </Button>
        <Button
          disabled={isLoading}
          onClick={(event) => void handleSubmit(onSubmit)(event)}
          variant="text"
        >
          {epi('saveButtonLabel')}
        </Button>
      </DialogActions>
    </>
  )
}

export default EvaluationReflectionForm
