import { Add } from '@mui/icons-material'
import { Box, Button, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import { useEpiCustomDictionary } from '@trr/app-shell-data'
import { any } from 'ramda'

import { useGetFeedbackQuery } from '../../utils/network/kompetensApi'
import { ContactStatus } from '../../types/IContactPerson.types'

import PersonsList from './components/PersonsList'
import AddContactModal from './components/AddContactModal'

interface IPersonligSpeglingProps {
  setShowReflection?: (v: boolean) => void
}

const PersonligSpegling = ({ setShowReflection }: IPersonligSpeglingProps) => {
  const [addContactModalOpen, setAddContactModalOpen] = useState(false)
  const epi = useEpiCustomDictionary(['innehall'])
  const handleAddContactClick = () => {
    setAddContactModalOpen(true)
  }

  const { data, isLoading, error } = useGetFeedbackQuery()

  useEffect(() => {
    if (setShowReflection) {
      if (
        data?.persons &&
        any((p) => p.status === ContactStatus.Completed, data.persons)
      ) {
        setShowReflection(true)
      } else setShowReflection(false)
    }
  }, [data, setShowReflection])

  const addPersonButton = (
    <Button
      sx={{ my: 3 }}
      size="small"
      variant="outlined"
      startIcon={<Add data-testid="add-icon" />}
      onClick={handleAddContactClick}
    >
      {epi('addContactButtonLabel')}
    </Button>
  )
  const hasContacts = data?.persons && data.persons.length > 0
  if (isLoading) return null
  if (error)
    return <Typography variant="h6">{epi('getFeedbackError')}</Typography>
  return (
    <Box sx={{ my: 3 }}>
      <AddContactModal
        open={addContactModalOpen}
        closeForm={() => {
          setAddContactModalOpen(false)
        }}
      />

      {hasContacts && addPersonButton}
      <PersonsList persons={data?.persons ?? []} />
      {!hasContacts && addPersonButton}
    </Box>
  )
}

export default PersonligSpegling
