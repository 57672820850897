import { IEvaluation } from '../../types/IEvaluation.types'

export const categorizeEvaluations = (
  evaluations: IEvaluation[]
): { [key: number]: IEvaluation[] } => {
  const categorizedEvaluations: { [key: number]: IEvaluation[] } = {
    6: [],
    5: [],
    4: [],
    3: [],
    2: [],
    1: [],
    0: [],
  }

  evaluations.map((e) => {
    categorizedEvaluations[e.value].push(e)
  })

  return categorizedEvaluations
}
