import { Box, Stack, Typography } from '@mui/material'

export interface IReflectionAnswers {
  hasAnswers?: boolean
  questions: {
    identifier: string
    i: number
    label: string
    sublabel?: string | undefined
  }[]
  answers:
    | {
        id: string
        value: string
      }[]
    | undefined
}

const ReflectionAnswers = ({
  questions,
  answers,
  hasAnswers,
}: IReflectionAnswers) => {
  if (!answers || !questions || !hasAnswers) return null
  return (
    <Box sx={{ position: 'relative' }}>
      <Stack gap={2}>
        {answers.map((r) => {
          if (r.value)
            return (
              <Box key={r.id}>
                <Typography sx={{ fontWeight: 'bold', mb: 1 }} variant="body1">
                  {questions.find((q) => q.identifier === r.id)?.label}
                </Typography>
                <Typography whiteSpace={'break-spaces'} variant="body1">
                  {r.value.trim()}
                </Typography>
              </Box>
            )
          else return null
        })}
      </Stack>
    </Box>
  )
}

export default ReflectionAnswers
