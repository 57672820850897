import { type SxProps, type Theme } from '@mui/material'
import { AutoStories, SportsBaseball, Work } from '@mui/icons-material'
import { useEpiCustomDictionary } from '@trr/app-shell-data'

import { LifeLineEventType } from '../../../App/App.types'

interface ITypeIconProps {
  type: LifeLineEventType | undefined
  sx?: SxProps<Theme> | undefined
}

const TypeIcon = ({ type, sx }: ITypeIconProps) => {
  const epi = useEpiCustomDictionary(['innehall'])

  switch (type) {
    case LifeLineEventType.Personal:
      return (
        <SportsBaseball
          data-testid="SportsBaseball-icon"
          sx={sx}
          aria-label={epi('personalLabel')}
          aria-hidden="false"
        />
      )

    case LifeLineEventType.Professional:
      return (
        <Work
          data-testid="Work-icon"
          sx={sx}
          aria-label={epi('professionalLabel')}
          aria-hidden="false"
        />
      )

    case LifeLineEventType.Educational:
      return (
        <AutoStories
          data-testid="AutoStories-icon"
          sx={sx}
          aria-label={epi('educationalLabel')}
          aria-hidden="false"
        />
      )

    default:
      return null
  }
}

export default TypeIcon
