/* eslint-disable @typescript-eslint/no-explicit-any */

import { useState, type ForwardedRef, forwardRef } from 'react'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3'
import { DesktopDatePicker as MuiDatePicker } from '@mui/x-date-pickers/DesktopDatePicker'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { sv } from 'date-fns/locale'

interface IYearPickerProps {
  name: string
  label: string
  customClass?: string
  disabled?: boolean
  testId?: string
  minDate?: Date
  maxDate?: Date
  value: Date | undefined
  onChange: (...event: any[]) => void
  error: string | undefined
}

const YearPicker = (
  {
    name,
    label,
    customClass,
    disabled,
    testId,
    minDate,
    maxDate,
    value,
    onChange,
    error,
  }: IYearPickerProps,
  ref: ForwardedRef<HTMLInputElement>
) => {
  const [open, setOpen] = useState(false)
  const handleOpenDatePicker = () => {
    setOpen(true)
  }
  const handleCloseDatePicker = () => {
    setOpen(false)
  }
  const defaultTestId = testId || name

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={sv}>
      <MuiDatePicker
        label={label}
        disabled={disabled}
        open={open}
        onChange={onChange}
        views={['year']}
        value={value ?? null}
        onOpen={handleOpenDatePicker}
        onClose={handleCloseDatePicker}
        minDate={minDate}
        maxDate={maxDate}
        inputRef={ref}
        className={customClass}
        slotProps={{
          textField: {
            placeholder: 'ÅÅÅÅ',
            error: Boolean(error),
            helperText: error,
            fullWidth: true,
            onClick: handleOpenDatePicker,
            disabled: disabled,
            inputProps: {
              'data-testid': defaultTestId,
            },
          },
          popper: {
            id: `${defaultTestId}-datepicker`,
          },
        }}
      />
    </LocalizationProvider>
  )
}

export default forwardRef(YearPicker)
