import { isRejectedWithValue } from '@reduxjs/toolkit'
import type { Middleware } from '@reduxjs/toolkit'

import { store } from '../store/configureStore'
import { addSystemFeedback } from '../../Features/SystemFeedback/systemFeedbackSlice'

interface ISystemFeedback {
  statusCode?: string | undefined
  text: string
  id: string
  duration?: number
  feedback: string
  message: string
  status: string
}

/**
 * Log a warning and show a toast!
 */

export const rtkQueryErrorLogger: Middleware = () => (next) => (action) => {
  if (isRejectedWithValue(action)) {
    const errorPayload = action.payload as ISystemFeedback
    if (process.env.NODE_ENV === 'development')
      console.error('RTK middleware error rejection:', action)
    if (errorPayload.feedback)
      store.dispatch(
        addSystemFeedback({
          id: errorPayload.message,
          statusCode: errorPayload.status,
          text: errorPayload.message || 'Något gick fel',
        })
      )
  }

  return next(action)
}
