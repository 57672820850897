import { IEvaluation } from '../../types/IEvaluation.types'

export const highlightedEvaluations = (evaluations: {
  [key: number]: IEvaluation[]
}): { [key: number]: IEvaluation[] } => {
  let thresholdAmount = 0
  for (let index = 1; index < 7; index++) {
    thresholdAmount = thresholdAmount + evaluations[7 - index].length
    evaluations[7 - index] = evaluations[7 - index].map((e) => ({
      ...e,
      highlighted: true,
    }))
    if (thresholdAmount > 4) {
      break
    }
  }

  return evaluations
}
