import { IMilestone } from '../../App/App.types'

export const groupMilestonesByYear = (
  milestones: IMilestone[]
): { [key: number]: IMilestone[] } =>
  milestones.reduce((acc: { [key: number]: IMilestone[] }, milestone) => {
    return {
      ...acc,
      [milestone.startYear]: acc[milestone.startYear]
        ? [...acc[milestone.startYear], milestone]
        : [milestone],
    }
  }, {})
