import { Button, useTheme } from '@mui/material'
import { useEpiContent } from '@trr/app-shell-data'

import { useTrackCustomClick } from '@trr/gtm-tracking'

import { ISimplePageTemplateContent } from '../../App/App.types'
import useDeviceSize from '../../utils/hooks/useDeviceSize'
import WysiwygEditor from '../../Components/WysiwygEditor'
import OpenInNew from '@mui/icons-material/OpenInNew'
import { isExternalURL } from 'utils/Helpers/isExternalURL'

interface IYrkesprognoserProps {
  setShowReflection?: (v: boolean) => void
}

const Yrkesprognoser = ({ setShowReflection }: IYrkesprognoserProps) => {
  const trackCustomClick = useTrackCustomClick()
  if (setShowReflection) setShowReflection(true)
  const { registerBlock, SimpleLinkBlock } =
    useEpiContent<ISimplePageTemplateContent>()
  const { palette } = useTheme()
  const { smallScreensUp } = useDeviceSize()
  const isExternalLink = isExternalURL(SimpleLinkBlock?.url)

  if (!SimpleLinkBlock)
    return (
      <WysiwygEditor
        styles={{
          bgcolor: palette.surface?.orange,
          p: smallScreensUp ? 6 : 2,
          mb: 7,
          fontSize: '16px',
        }}
        htmlFormattedString={registerBlock?.mainBody ?? ''}
      />
    )
  else
    return (
      <Button
        target={isExternalLink ? '_blank' : '_self'}
        startIcon={isExternalLink && <OpenInNew />}
        component={'a'}
        sx={{ maxWidth: 'fit-content' }}
        variant="outlined"
        href={SimpleLinkBlock?.url}
        onClick={() => {
          trackCustomClick('yrkesprognoser', { label: 'Påbörjad' })
          trackCustomClick('yrkesprognoser', { label: 'Genomförd' })
        }}
      >
        {SimpleLinkBlock?.text}
      </Button>
    )
}

export default Yrkesprognoser
