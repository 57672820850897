import { createSlice } from '@reduxjs/toolkit'

export interface IEvaluationDelete {
  open: boolean
}

const initialState: IEvaluationDelete = {
  open: false,
}

export const evaluationDelete = createSlice({
  name: 'evaluationDelete',
  initialState,
  reducers: {
    openDeleteModal: (state) => {
      state.open = true
    },
    closeDeleteModal: (state) => {
      state.open = false
    },
  },
})

export const { openDeleteModal, closeDeleteModal } = evaluationDelete.actions

export default evaluationDelete.reducer
