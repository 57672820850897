import { Button, Dialog, DialogContent, Stack, Typography } from '@mui/material'

import { useEpiContent, useEpiCustomDictionary } from '@trr/app-shell-data'

import EpiImage from '../../../Components/EpiImage/EpiImage'
import { IVarderingskompassenContent } from '../../../App/App.types'
import useDeviceSize from '../../../utils/hooks/useDeviceSize'

interface IEvaluatedModalProps {
  open: boolean
  onClose: () => void
  onProceedClick: () => void
}

const EvaluatedModal = ({
  onClose,
  open,
  onProceedClick,
}: IEvaluatedModalProps) => {
  const { evaluatedModal } = useEpiContent<IVarderingskompassenContent>()

  const epi = useEpiCustomDictionary(['evaluatedModal'])

  const { smallScreensUp } = useDeviceSize()
  return (
    <Dialog
      onClose={onClose}
      open={open}
      aria-labelledby="evaluated-modal-title"
    >
      <DialogContent sx={{}}>
        <Stack direction="row">
          <Stack justifyContent="center" sx={{ textAlign: 'center' }}>
            <Typography
              id="evaluated-modal-title"
              gutterBottom
              variant="h5"
              component="h2"
            >
              {epi('header')}
            </Typography>
            <Typography gutterBottom variant="body1">
              {epi('paragraph')}
            </Typography>
            <Button
              data-testid="proceedButton"
              onClick={onProceedClick}
              variant="text"
            >
              {epi('proceedButtonLabel')}
            </Button>
          </Stack>
          {smallScreensUp && (
            <EpiImage width={'300'} url={evaluatedModal.imageUrl} />
          )}
        </Stack>
      </DialogContent>
    </Dialog>
  )
}

export default EvaluatedModal
