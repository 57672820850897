import { Dialog, DialogTitle } from '@mui/material'

import { any, isEmpty } from 'ramda'

import {
  useDeleteContactFeedbackMutation,
  useGetFeedbackQuery,
  useUpdateContactFeedbackMutation,
} from '../../../utils/network/kompetensApi'
import useDeviceSize from '../../../utils/hooks/useDeviceSize'

import FeedbackForm from './FeedbackForm'

const hasAnswers = (feedbacks: { identifier: string; answer: string }[]) =>
  any((f) => !isEmpty(f.answer), feedbacks)

interface IUpdateFeedbackModalProps {
  open: boolean
  name: string
  id: string
  closeForm: () => void
}

const UpdateFeedbackModal = ({
  open,
  closeForm,
  name,
  id,
}: IUpdateFeedbackModalProps) => {
  const [updateFeedback, { isLoading: isUpdatingFeedback }] =
    useUpdateContactFeedbackMutation()
  const [deleteFeedback, { isLoading: isDeletingFeedback }] =
    useDeleteContactFeedbackMutation()
  const { smallScreensUp } = useDeviceSize()
  const onSubmit = (feedbacks: { identifier: string; answer: string }[]) => {
    if (hasAnswers(feedbacks)) {
      updateFeedback({
        id,
        feedbacks,
      })
        .unwrap()
        .then(() => {
          closeForm()
        })
        .catch((e: unknown) => {
          console.log(e)
        })
    } else {
      deleteFeedback(id)
        .unwrap()
        .then(() => {
          closeForm()
        })
        .catch((e: unknown) => {
          console.log(e)
        })
    }
  }

  const { data } = useGetFeedbackQuery()

  const isLoading = isDeletingFeedback || isUpdatingFeedback
  const feedback = data?.persons.find((p) => p.id === id)?.feedbacks
  if (!open) return null
  return (
    <Dialog
      onClick={(event) => {
        event.stopPropagation()
      }}
      fullScreen={!smallScreensUp}
      scroll="paper"
      fullWidth
      open={open}
      onClose={closeForm}
      aria-labelledby="update-feedback-title"
    >
      <DialogTitle id="update-feedback-title">{name}</DialogTitle>
      <FeedbackForm
        isLoading={isLoading}
        defaultValues={feedback}
        onFormSubmit={onSubmit}
        closeForm={closeForm}
      />
    </Dialog>
  )
}

export default UpdateFeedbackModal
