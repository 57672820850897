import { useState } from 'react'
import {
  Box,
  Stack,
  TextField,
  Typography,
  Button,
  useTheme,
} from '@mui/material'
import { useEpiContent } from '@trr/app-shell-data'
import { useForm, Controller } from 'react-hook-form'
import { all } from 'ramda'

import { IEditorialBlockContent } from '../../../App/App.types'
import WysiwygEditor from '../../../Components/WysiwygEditor'
import { useUpdateFramtidsmalMutation } from '../../../utils/network/kompetensApi'
import { IFramtidsmal } from '../../../types/IFramtidsmal.types'
import CancelFormDialog from '../../../Components/CancelFormDialog/CancelFormDialog'
import { useCombineEpiCustomDictionary } from '../../../utils/hooks/useCombineEpiCustomDictionary'

interface IFramtidsmalEditProps {
  framtidsmal: IFramtidsmal | undefined
  onSubmit: () => void
  onCancel: () => void
}

export interface IFramtidsmalContent {
  [key: string]: unknown
  karriarmal: IEditorialBlockContent
  studiemal: IEditorialBlockContent
  forvantningar: IEditorialBlockContent
}

const FramtidsmalForm = ({
  framtidsmal,
  onSubmit,
  onCancel,
}: IFramtidsmalEditProps) => {
  const [closeDialog, setCloseDialog] = useState(false)
  const {
    handleSubmit,
    formState: { errors, isDirty },
    control,
  } = useForm({
    defaultValues: framtidsmal,
  })
  const { palette } = useTheme()
  const epi = useCombineEpiCustomDictionary([
    'innehall',
    'inputValidationTexts',
  ])
  const { karriarmal, studiemal, forvantningar } =
    useEpiContent<IFramtidsmalContent>()
  const [updateFramtidsmal] = useUpdateFramtidsmalMutation()

  const submit = handleSubmit(({ careerGoals, studyGoals, expectations }) => {
    const updatedFramtidsmal = {
      careerGoals,
      studyGoals,
      expectations,
    }
    void updateFramtidsmal(updatedFramtidsmal).then(() => {
      onSubmit()
    })
  })

  const handleCancelClicked = () => {
    if (isDirty) setCloseDialog(true)
    else onCancel()
  }

  const framtidsmalIsEmpty = all(
    (w: string) => !w,
    [
      framtidsmal?.careerGoals as string,
      framtidsmal?.expectations as string,
      framtidsmal?.studyGoals as string,
    ]
  )
  return (
    <>
      {onCancel && (
        <CancelFormDialog
          onCancel={onCancel}
          open={closeDialog}
          setOpen={setCloseDialog}
        />
      )}
      <Stack gap={2}>
        <form
          data-testid="framtidsmal-form"
          onSubmit={(event) => void submit(event)}
        >
          {karriarmal && (
            <>
              <Typography
                variant="h6"
                component="h3"
                sx={{
                  mt: 3,
                  mb: 1,
                }}
              >
                {karriarmal.name}
              </Typography>
              <Box
                sx={{
                  bgcolor: palette.surface?.grey,
                  fontSize: '16px',
                  mb: 2,
                }}
              >
                <WysiwygEditor
                  htmlFormattedString={karriarmal.mainBody}
                  styles={{
                    p: 3,
                  }}
                />
              </Box>
              <Controller
                name={'careerGoals'}
                control={control}
                rules={{
                  maxLength: {
                    value: 2000,
                    message: epi('maxLength2000ErrorMessage'),
                  },
                }}
                render={({ field }) => (
                  <TextField
                    inputProps={{
                      'data-testid': 'careerGoals',
                      'aria-label': karriarmal.name,
                    }}
                    helperText={
                      errors['careerGoals']
                        ? (errors['careerGoals'].message as string)
                        : ''
                    }
                    error={!!errors['careerGoals']?.message}
                    fullWidth
                    multiline
                    rows={5}
                    value={field.value ?? ''}
                    onChange={field.onChange}
                    sx={{ mb: 3 }}
                    data-testid="framtidsmal-karriarmal-input"
                  />
                )}
              />
            </>
          )}
          {studiemal && (
            <>
              <Typography
                variant="h6"
                component="h3"
                sx={{
                  mt: 3,
                  mb: 1,
                }}
              >
                {studiemal.name}
              </Typography>
              <Box
                sx={{
                  bgcolor: palette.surface?.grey,
                  fontSize: '16px',
                  mb: 2,
                }}
              >
                <WysiwygEditor
                  htmlFormattedString={studiemal.mainBody}
                  styles={{
                    p: 3,
                  }}
                />
              </Box>
              <Controller
                name={'studyGoals'}
                control={control}
                rules={{
                  maxLength: {
                    value: 2000,
                    message: epi('maxLength2000ErrorMessage'),
                  },
                }}
                render={({ field }) => (
                  <TextField
                    inputProps={{
                      'data-testid': 'studyGoals',
                      'aria-label': studiemal.name,
                    }}
                    helperText={
                      errors['studyGoals']
                        ? (errors['studyGoals'].message as string)
                        : ''
                    }
                    error={!!errors['studyGoals']?.message}
                    fullWidth
                    multiline
                    rows={5}
                    value={field.value ?? ''}
                    onChange={field.onChange}
                    sx={{ mb: 3 }}
                    data-testid="framtidsmal-studiemal-input"
                  />
                )}
              />
            </>
          )}
          {forvantningar && (
            <>
              <Typography
                variant="h6"
                component="h3"
                sx={{
                  mt: 3,
                  mb: 1,
                }}
              >
                {forvantningar.name}
              </Typography>
              <Box
                sx={{
                  bgcolor: palette.surface?.grey,
                  fontSize: '16px',
                  mb: 2,
                }}
              >
                <WysiwygEditor
                  htmlFormattedString={forvantningar.mainBody}
                  styles={{
                    p: 3,
                  }}
                />
              </Box>
              <Controller
                rules={{
                  maxLength: {
                    value: 2000,
                    message: epi('maxLength2000ErrorMessage'),
                  },
                }}
                name={'expectations'}
                control={control}
                render={({ field }) => (
                  <TextField
                    inputProps={{
                      'data-testid': 'expectations',
                      'aria-label': forvantningar.name,
                    }}
                    helperText={
                      errors['expectations']
                        ? (errors['expectations'].message as string)
                        : ''
                    }
                    error={!!errors['expectations']?.message}
                    fullWidth
                    multiline
                    rows={5}
                    value={field.value ?? ''}
                    onChange={field.onChange}
                    sx={{ mb: 3 }}
                    data-testid="framtidsmal-forvantningar-input"
                  />
                )}
              />
            </>
          )}
          <Stack gap={2} direction={'row'}>
            {!framtidsmalIsEmpty && (
              <Button
                data-testid="cancel-button"
                variant="text"
                onClick={handleCancelClicked}
              >
                {epi('cancel')}
              </Button>
            )}
            <Button data-testid="save-button" type="submit" variant="text">
              {epi('save')}
            </Button>
          </Stack>
        </form>
      </Stack>
    </>
  )
}

export default FramtidsmalForm
