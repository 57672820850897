export const cleanPercentage = (percentage: number) => {
  const tooLow = !Number.isFinite(+percentage) || percentage < 0
  const tooHigh = percentage > 100
  return tooLow ? 0 : tooHigh ? 100 : +percentage
}

interface ICircleProps {
  color: string
  pct: number
  width?: string
}

const Circle = ({ color, pct, width = '1rem' }: ICircleProps) => {
  const r = 70
  const circ = 2 * Math.PI * r
  const strokePct = ((100 - pct) * circ) / 100
  return (
    <circle
      r={r}
      cx={100}
      cy={100}
      fill="transparent"
      role="progressbar"
      stroke={strokePct !== circ ? color : ''}
      strokeWidth={width}
      strokeDasharray={circ}
      strokeDashoffset={-strokePct}
    />
  )
}

interface IProgressCircleProps {
  percentage: number
  color: string
  secondaryColor: string
}
const ProgressCircle = ({
  percentage,
  color,
  secondaryColor,
}: IProgressCircleProps) => {
  const pct = cleanPercentage(percentage)
  return (
    <svg width={200} height={200}>
      <g transform={`rotate(-90 100 100)`}>
        <Circle width={'.92rem'} pct={100} color={color} />
        <Circle color={secondaryColor} pct={pct} />
      </g>
    </svg>
  )
}

export default ProgressCircle
