import { Box, type SxProps } from '@mui/material'
import React from 'react'

const wysiwygEditorStyles = {
  '&': { wordWrap: 'break-word' },
  '& h4': { marginTop: '5px' },
  '& p': { fontSize: '16px', marginBottom: '10px' },
  '& a': { color: 'rgb(98, 34, 195)', textDecoration: 'none' },
  '& a:hover': { textDecoration: 'underline' },
}

const WysiwygEditor = ({
  htmlFormattedString,
  styles,
}: {
  htmlFormattedString: string
  styles?: SxProps
}): React.JSX.Element => (
  <Box
    sx={{ ...wysiwygEditorStyles, ...styles }}
    dangerouslySetInnerHTML={{ __html: htmlFormattedString }}
  />
)

export default WysiwygEditor
