export const getQuestionsFromContent = (content: { [key: string]: string }) => {
  const arr = [] as {
    identifier: string
    i: number
    label: string
    sublabel?: string
  }[]
  Object.entries(content).map((e) => {
    if (e[0].includes('question') && e[0].includes('Identifier')) {
      arr.push({
        i: parseInt(e[0].replace(/(^.+\D)(\d+)(\D.+$)/i, '$2')),
        identifier: e[1],
        label: '',
      })
    }
  })
  Object.entries(content).map((e) => {
    const index = parseInt(e[0].replace(/(^.+\D)(\d+)(\D.+$)/i, '$2'))
    if (e[0].includes('SubLabel')) {
      const itemIndex = arr.findIndex((i) => i.i === index)
      if (arr[itemIndex]) arr[itemIndex].sublabel = e[1]
    }
    if (e[0].includes('Label') && !e[0].includes('SubLabel')) {
      const itemIndex = arr.findIndex((i) => i.i === index)
      if (arr[itemIndex]) arr[itemIndex].label = e[1]
    }
  })
  return arr
}
