export const adjustLink = (inputLink: string): string => {
  // Extract the ID from the input link
  const idRegex = /\/welcome\/([^/]+)/
  const match = inputLink.match(idRegex)

  if (!match || match.length < 2) {
    return inputLink
  }

  const id = match[1]

  // Construct the adjusted link with the preserved protocol, domain, and using the ID twice
  const adjustedLink = inputLink.replace(
    idRegex,
    () => `/test-reports/career-search/${id}/${id}`
  )

  return adjustedLink
}
