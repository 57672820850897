import {
  Box,
  ButtonBase,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Fade,
  Stack,
  Typography,
  useTheme,
} from '@mui/material'
import { useEffect, useState } from 'react'
import { useEpiCustomDictionary } from '@trr/app-shell-data'

import { usePostEvaluationMutation } from '../../../utils/network/kompetensApi'
import useDeviceSize from '../../../utils/hooks/useDeviceSize'

interface IEvaluationCardProps {
  label: string
  description: string
  id: string
  inFocus?: boolean
}

const EvaluationCard = ({
  label,
  description,
  id,
  inFocus,
}: IEvaluationCardProps) => {
  const theme = useTheme()
  const [evaluate, { isLoading }] = usePostEvaluationMutation()
  const [evaluatedAnimation, setEvaluatedAnimation] = useState<number | null>(
    null
  )
  const epi = useEpiCustomDictionary(['evaluate'])
  const onEvaluateClick = (value: number) => {
    setEvaluatedAnimation(value)
    setTimeout(() => {
      evaluate({
        id,
        value,
      }).catch((e: unknown) => {
        console.log(e)
      })
    }, 500)
  }

  const { mediumScreensUp } = useDeviceSize()

  useEffect(() => {
    setEvaluatedAnimation(null)
  }, [inFocus, id])
  return (
    <Card
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        height: '250px',
        width: mediumScreensUp ? '420px' : '320px',
      }}
    >
      {evaluatedAnimation ? (
        <CardContent
          sx={{
            display: 'flex',
            height: '100%',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Fade in={evaluatedAnimation !== null}>
            <div>
              <Typography
                data-testid="evaluated-animation-number"
                variant="h3"
                component="p"
                sx={{ fontSize: 80 }}
              >
                {evaluatedAnimation}
              </Typography>
            </div>
          </Fade>
        </CardContent>
      ) : (
        <>
          <CardHeader
            subheader={description}
            title={label}
            titleTypographyProps={{ component: 'h3' }}
          />
          <CardContent>
            <Stack
              justifyContent="space-around"
              divider={<Divider orientation="vertical" flexItem />}
              sx={{ mx: 1 }}
              direction="row"
            >
              {[1, 2, 3, 4, 5, 6].map((i) => (
                <ButtonBase
                  sx={{
                    '&:hover': {
                      backgroundColor: theme.palette.surface?.purple,
                      borderRadius: '50%',
                    },
                  }}
                  focusRipple
                  disabled={isLoading || !inFocus}
                  key={i}
                  onClick={() => {
                    onEvaluateClick(i)
                  }}
                >
                  <Box
                    sx={{
                      width: '48px',
                      display: 'flex',
                      height: '48px',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    {i}
                  </Box>
                </ButtonBase>
              ))}
            </Stack>
            <Divider flexItem />
            <Stack justifyContent="space-between" direction="row">
              <Typography variant="subtitle2" component="p">
                {epi('noPriorityText')}
              </Typography>
              <Typography variant="subtitle2" component="p">
                {epi('fullPriorityText')}
              </Typography>
            </Stack>
          </CardContent>
        </>
      )}
    </Card>
  )
}

export default EvaluationCard
