import { useEpiContent } from '@trr/app-shell-data'
import React, { useEffect, useState } from 'react'
import { Button, Divider, Stack, Typography, useTheme } from '@mui/material'
import { ArrowBack } from '@mui/icons-material'

import { ISimplePageTemplateContent } from '../../App/App.types'
import EpiImage from '../../Components/EpiImage/EpiImage'
import WysiwygEditor from '../../Components/WysiwygEditor'
import Reflection from '../Reflection/Reflection'
import { mapToListTexts } from '../../utils/Helpers/mapToListTexts'
import useDeviceSize from '../../utils/hooks/useDeviceSize'
import useQueryParams from '../../utils/hooks/useQueryParams'

interface ISimplePageTemplageProps {
  identifier: string
  customContent?: React.JSX.Element
}
const SimplePageTemplate = ({
  identifier,
  customContent,
}: ISimplePageTemplageProps) => {
  const { ingress, list, howTo, reflection, backLink } =
    useEpiContent<ISimplePageTemplateContent>()
  const listTexts = list ? mapToListTexts(list) : []
  const { palette } = useTheme()
  const [showReflection, setShowReflection] = useState(
    customContent ? false : true
  )
  const { smallScreensUp } = useDeviceSize()

  const reflectionRef = React.useRef<HTMLInputElement>(null)
  const f = useQueryParams('f')
  useEffect(() => {
    if (f === 'reflection') {
      setTimeout(() => {
        reflectionRef.current?.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
        })
      }, 300)
    }
  }, [f, reflection])

  return (
    <Stack gap={smallScreensUp ? 5 : 3}>
      {ingress && (
        <Stack direction="row">
          <Typography variant="preamble" data-testid="ingress">
            {ingress.text}
          </Typography>
          {smallScreensUp && <EpiImage url={ingress.imageUrl} />}
        </Stack>
      )}
      {list && (
        <Stack direction={smallScreensUp ? 'row' : 'column'}>
          {listTexts.map((i) => (
            <Typography
              sx={{
                borderLeft: `solid 5px ${palette.grey[100]}`,
                pl: 1,
                pr: 3,
                mb: smallScreensUp ? 0 : 2,
              }}
              key={i}
              variant="body1"
            >
              {i}
            </Typography>
          ))}
        </Stack>
      )}
      {howTo && <WysiwygEditor htmlFormattedString={howTo.mainBody} />}
      {customContent &&
        React.cloneElement(customContent, {
          setShowReflection,
          showReflection,
        })}

      {reflection && (
        <>
          <Divider />
          <div ref={reflectionRef}>
            <Reflection
              showReflection={showReflection}
              identifier={identifier}
              content={reflection}
            />
          </div>
        </>
      )}
      {backLink && (
        <Button
          sx={{ maxWidth: 'fit-content' }}
          startIcon={<ArrowBack />}
          variant="text"
          href={backLink.url}
        >
          {backLink.label}
        </Button>
      )}
    </Stack>
  )
}

export default SimplePageTemplate
