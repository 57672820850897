import { useState, useEffect } from 'react'
import { CircularProgress, Typography } from '@mui/material'
import { isEmpty } from 'ramda'
import { useEpiCustomDictionary } from '@trr/app-shell-data'

import { useGetMilestonesQuery } from '../../utils/network/kompetensApi'

import AddMilestone from './components/AddMilestone'
import MilestonesList from './components/MilestonesList'
import MilestoneHeader from './components/MilestoneHeader'

interface ILivslinjenProps {
  setShowReflection?: (v: boolean) => void
}

const Livslinjen = ({ setShowReflection }: ILivslinjenProps) => {
  const [focusedMilestone, setFocusedMilestone] = useState<string | undefined>()
  const epi = useEpiCustomDictionary(['innehall'])
  const {
    data: milestones = [],
    isLoading: isMilestonesLoading,
    error,
  } = useGetMilestonesQuery()

  useEffect(() => {
    if (setShowReflection) {
      if (!isEmpty(milestones)) setShowReflection(true)
      else setShowReflection(false)
    }
  }, [milestones, setShowReflection])

  if (isMilestonesLoading) return <CircularProgress sx={{ margin: 'auto' }} />
  if (error)
    return <Typography variant="h6">{epi('getLivslinjenError')}</Typography>
  if (isEmpty(milestones))
    return (
      <>
        <MilestoneHeader />
        <AddMilestone
          onAdded={(v) => {
            setFocusedMilestone(v)
          }}
          formOpen={false}
        />
      </>
    )
  return (
    <MilestonesList
      focusedMilestone={focusedMilestone}
      setFocusedMilestone={setFocusedMilestone}
      isLoading={isMilestonesLoading}
      milestones={milestones ?? []}
    />
  )
}

export default Livslinjen
