import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

export interface IEvaluationReflection {
  value: string
}

const initialState: IEvaluationReflection = {
  value: '',
}

export const evaluationReflection = createSlice({
  name: 'evaluationReflection',
  initialState,
  reducers: {
    openModal: (state, action: PayloadAction<string>) => {
      state.value = action.payload
    },
    closeModal: (state) => {
      state.value = ''
    },
  },
})

export const { openModal, closeModal } = evaluationReflection.actions

export default evaluationReflection.reducer
