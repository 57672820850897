export enum ContactStatus {
  AwaitingFeedback = 0,
  Completed = 1,
  Unknown = 2,
}

export interface IContactPerson {
  id: string
  name: string
  status: ContactStatus
  feedbacks?: {
    identifier: string
    answer: string
  }[]
}
