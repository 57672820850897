import { Stack, Typography, useTheme } from '@mui/material'
import { useEpiCustomDictionary } from '@trr/app-shell-data'

import useDeviceSize from '../../../utils/hooks/useDeviceSize'

import { AmountLeftCard } from './AmountLeftCard'

export interface IReflectionsLeftBoxProps {
  amountLeft: number
}

const ReflectionsLeftBox = ({ amountLeft }: IReflectionsLeftBoxProps) => {
  const epi = useEpiCustomDictionary(['reflect'])
  const { palette } = useTheme()

  const { smallScreensUp } = useDeviceSize()

  if (amountLeft < 1) return null
  return (
    <Stack
      justifyContent="space-between"
      direction={smallScreensUp ? 'row' : 'column'}
      sx={{ p: 5 }}
      bgcolor={palette.surface?.purple}
    >
      <Stack alignItems="center" direction="row" component="h2">
        <Typography variant="h6" component="span">
          {epi('reflectionsLeft1')}
        </Typography>
        <Typography sx={{ px: 2 }} variant="h1" component="span">
          {amountLeft}
        </Typography>
        <Typography variant="h6" component="span">
          {amountLeft === 1 ? epi('reflectionsLeft4') : epi('reflectionsLeft3')}
        </Typography>
      </Stack>
      <Stack
        sx={{ mx: 5, mt: smallScreensUp ? 0 : 2 }}
        alignItems="flex-end"
        direction="row-reverse"
      >
        <AmountLeftCard tilt={24} completed={amountLeft < 1} number={3} />
        <AmountLeftCard completed={amountLeft < 2} number={2} />
        <AmountLeftCard tilt={-24} completed={amountLeft < 3} number={1} />
      </Stack>
    </Stack>
  )
}

export default ReflectionsLeftBox
