export const mapToListTexts = (list: {
  text1: string
  text2: string
  text3: string
}) => {
  const arr = [] as string[]
  Object.entries(list).map((e) => {
    if (e[0].includes('text') && e[1]) arr.push(e[1])
  })

  return arr
}
