import { configureStore } from '@reduxjs/toolkit'
// Or from '@reduxjs/toolkit/query/react'
import { setupListeners } from '@reduxjs/toolkit/query'

import { jmtApi } from '../network/jmtApi'
import { kompetensApi } from '../network/kompetensApi'
import evaluationReflectionReducer from '../../Features/Varderingskompassen/evaluationReflectionSlice'
import evaluationDeleteReducer from '../../Features/Varderingskompassen/evaluationDeleteSlice'
import systemFeedbackReducer from '../../Features/SystemFeedback/systemFeedbackSlice'
import { rtkQueryErrorLogger } from '../network/rejectMiddleware'

export const store = configureStore({
  reducer: {
    evaluationReflection: evaluationReflectionReducer,
    evaluationDelete: evaluationDeleteReducer,
    systemFeedback: systemFeedbackReducer,
    [jmtApi.reducerPath]: jmtApi.reducer,
    [kompetensApi.reducerPath]: kompetensApi.reducer,
  },

  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .concat(jmtApi.middleware)
      .concat(kompetensApi.middleware)
      .concat(rtkQueryErrorLogger),
})

setupListeners(store.dispatch)

export type RootState = ReturnType<typeof store.getState>

export type AppDispatch = typeof store.dispatch
