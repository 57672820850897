import {
  Box,
  ButtonBase,
  ListItem,
  Stack,
  Typography,
  useTheme,
} from '@mui/material'
import { ChevronRightRounded, VerifiedRounded } from '@mui/icons-material'
import { useEpiCustomDictionary } from '@trr/app-shell-data'

import { ILinkBlock } from '../../../../App/App.types'
import EpiImage from '../../../../Components/EpiImage/EpiImage'
import useDeviceSize from '../../../../utils/hooks/useDeviceSize'
import ReflectionButton from '../ReflectionButton'

interface IToolLinkProps {
  link: ILinkBlock
  completed?: boolean
  hideBottomBorder?: boolean
  blockKey?: string
  name?: string
}

const ToolLink = ({
  link,
  completed,
  hideBottomBorder,
  blockKey,
  name,
}: IToolLinkProps) => {
  const { url, illustration, heading, duration, ingress } = link
  const { palette } = useTheme()

  const { mediumScreensUp, smallScreensUp } = useDeviceSize()
  const epi = useEpiCustomDictionary(['innehall'])
  return (
    <ListItem
      disablePadding
      sx={{
        borderBottom: hideBottomBorder
          ? 'none'
          : `solid 1px ${palette.divider}`,
        '&:hover': {
          background: palette.grey[100],
        },
        ':last-child': {
          borderBottom: 'none',
        },
      }}
    >
      <ButtonBase
        href={url}
        sx={{
          textAlign: 'unset',
          width: '100%',
          ':focus-visible': {
            outline: '-webkit-focus-ring-color auto 1px',
          },
        }}
        data-gtm-label={name}
      >
        <Stack
          justifyContent={'space-between'}
          alignItems={'center'}
          direction="row"
          sx={{ width: '100%' }}
        >
          <Stack direction="row">
            <Box
              sx={{
                p: mediumScreensUp ? 0.5 : 0,
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <EpiImage
                height={'unset'}
                width={smallScreensUp ? '150' : '60'}
                url={illustration}
              />
            </Box>
            <Box sx={{ ml: mediumScreensUp ? 2 : 0.5, p: 3, px: 0 }}>
              <Stack
                sx={{ mb: 1 }}
                justifyContent="space-between"
                direction="row"
              >
                <Stack direction="row" gap={1}>
                  {completed && (
                    <VerifiedRounded
                      color="success"
                      aria-label={epi('klarmarkerad')}
                      aria-hidden="false"
                    />
                  )}
                  <Typography variant="h6" component="h3">
                    {heading}
                  </Typography>
                </Stack>

                {!completed && (
                  <Typography
                    sx={{
                      display: {
                        xs: 'none',
                        sm: 'block',
                      },
                    }}
                    variant="body2"
                  >
                    {duration}
                  </Typography>
                )}
              </Stack>

              <Typography sx={{ pr: mediumScreensUp ? 0 : 1 }} variant="body2">
                {ingress}
              </Typography>
              {completed && blockKey !== 'framtidsmal' && (
                <ReflectionButton url={url} />
              )}
            </Box>
          </Stack>
          {mediumScreensUp && (
            <ChevronRightRounded sx={{ m: 2, color: palette.grey[500] }} />
          )}
        </Stack>
      </ButtonBase>
    </ListItem>
  )
}

export default ToolLink
