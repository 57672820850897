import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material'
import { useEpiCustomDictionary } from '@trr/app-shell-data'

import { useDeleteMilestoneMutation } from '../../../utils/network/kompetensApi'

interface IDeleteMilestoneModalProps {
  setOpen: (v: boolean) => void
  open: boolean
  id: string
}

const DeleteMilestoneModal = ({
  setOpen,
  open,
  id,
}: IDeleteMilestoneModalProps) => {
  const epi = useEpiCustomDictionary(['deleteMilestoneModal'])
  const [deleteMilestone, { isLoading }] = useDeleteMilestoneMutation()
  const handleSaveClick = () => {
    deleteMilestone(id)
      .unwrap()
      .then(() => {
        setOpen(false)
      })
      .catch((e: unknown) => {
        console.error(e)
      })
  }
  if (!open) return null
  return (
    <Dialog
      scroll="paper"
      fullWidth
      open={open}
      onClose={() => {
        setOpen(false)
      }}
      data-testid="delete-contact-dialog"
      aria-labelledby="delete-contact-title"
    >
      <DialogTitle id="delete-contact-title">{epi('header')}</DialogTitle>
      <DialogContent>
        <DialogContentText sx={{ mb: 3 }}>{epi('ingress')}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          variant="text"
          onClick={() => {
            setOpen(false)
          }}
        >
          {epi('cancelButtonLabel')}
        </Button>
        <Button
          data-testid="deleteMilestoneButton"
          disabled={isLoading}
          onClick={() => {
            handleSaveClick()
          }}
          variant="text"
        >
          {epi('deleteButtonLabel')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default DeleteMilestoneModal
