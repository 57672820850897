import { Typography } from '@mui/material'
import { PropsWithChildren } from 'react'

export const HiddenText = ({ children }: PropsWithChildren) => {
  const style = {
    position: 'absolute',
    top: '-3000px',
    left: '-3000px',
    width: 0,
    height: 0,
    lineHeight: 0,
    fontSize: 0,
  }
  return <Typography sx={style}>{children}</Typography>
}
