import { OpenInNew } from '@mui/icons-material'
import { Button, useTheme } from '@mui/material'
import { useEpiContent } from '@trr/app-shell-data'
import { useTrackCustomClick } from '@trr/gtm-tracking'

import WysiwygEditor from '../../Components/WysiwygEditor'
import useDeviceSize from '../../utils/hooks/useDeviceSize'
import { ISimplePageTemplateContent } from '../../App/App.types'
import { isExternalURL } from 'utils/Helpers/isExternalURL'

interface IUtbildningarProps {
  setShowReflection?: (v: boolean) => void
}

const Utbildningar = ({ setShowReflection }: IUtbildningarProps) => {
  if (setShowReflection) setShowReflection(true)
  const { registerBlock, SimpleLinkBlock } =
    useEpiContent<ISimplePageTemplateContent>()
  const { palette } = useTheme()
  const { smallScreensUp } = useDeviceSize()
  const trackCustomClick = useTrackCustomClick()
  const isExternalLink = isExternalURL(SimpleLinkBlock?.url)

  if (!SimpleLinkBlock)
    return (
      <WysiwygEditor
        styles={{
          bgcolor: palette.surface?.orange,
          p: smallScreensUp ? 6 : 2,
          mb: 7,
          fontSize: '16px',
        }}
        htmlFormattedString={registerBlock?.mainBody ?? ''}
      />
    )

  return (
    <Button
      data-testid="cta-button"
      target={isExternalLink ? '_blank' : '_self'}
      startIcon={isExternalLink && <OpenInNew />}
      component={'a'}
      sx={{ maxWidth: 'fit-content' }}
      variant="outlined"
      href={SimpleLinkBlock?.url}
      aria-label={`${SimpleLinkBlock?.text} ${isExternalLink ? '(öppnas i ny flik)' : ''}`}
      onClick={() => {
        trackCustomClick('kurser-och-utbildningar', { label: 'Påbörjad' })
        trackCustomClick('kurser-och-utbildningar', { label: 'Genomförd' })
      }}
    >
      {SimpleLinkBlock?.text}
    </Button>
  )
}

export default Utbildningar
