import { getImageQuery } from '../../utils/Helpers/getImageQuery'
import { getAppConfig } from '@trr/app-shell-data'
const EpiImage = ({
  url,
  width = '300',
  height = '100%',
}: {
  url: string
  width?: string
  height?: string
}): React.JSX.Element => {
  const { MEDIA_URL } = getAppConfig().COMMON

  if (!url) return <></>
  const expandedUrl = `${MEDIA_URL}${url}`
  return (
    <>
      <img
        width={width}
        src={getImageQuery(expandedUrl, 800)}
        alt=""
        height={height}
      />
    </>
  )
}

export default EpiImage
