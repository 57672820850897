import { useHistory } from 'react-router-dom'

const useQueryParams = (key: string) => {
  const history = useHistory()
  const name = new URLSearchParams(history.location.search).get(key)

  return name
}

export default useQueryParams
