import { Button, Card, CardActionArea, Stack, Typography } from '@mui/material'
import { CheckCircleOutline } from '@mui/icons-material'
import { useDispatch } from 'react-redux'

import { IEvaluation } from '../../../types/IEvaluation.types'
import { openModal } from '../evaluationReflectionSlice'

interface IEvaluationButtonProps {
  evaluation: IEvaluation
  disabled: boolean
}

const EvaluationButton = ({ evaluation, disabled }: IEvaluationButtonProps) => {
  const dispatch = useDispatch()
  const clickDisabled = evaluation.hasReflection || disabled
  return (
    <>
      {evaluation.highlighted ? (
        <Card sx={{ minWidth: '250px', width: 'fit-content' }}>
          <CardActionArea
            disabled={clickDisabled}
            sx={{ px: 3, py: 2, opacity: clickDisabled ? 0.6 : 1 }}
            onClick={() => dispatch(openModal(evaluation.id))}
          >
            <Stack gap={1} direction="row">
              <Typography variant="body1" fontWeight="bold">
                {evaluation.term}
              </Typography>
              {evaluation.hasReflection && (
                <CheckCircleOutline color="success" />
              )}
            </Stack>
          </CardActionArea>
        </Card>
      ) : (
        <Button
          disabled={clickDisabled}
          onClick={() => dispatch(openModal(evaluation.id))}
          sx={{
            '&.Mui-disabled': {
              color: 'rgba(0, 0, 0, 0.60)',
            },
            color: 'black',
            borderColor: 'black',
          }}
          size="small"
          variant="outlined"
          endIcon={
            evaluation.hasReflection && (
              <CheckCircleOutline
                color="success"
                sx={{ opacity: clickDisabled ? 0.6 : 1 }}
              />
            )
          }
        >
          {evaluation.term}
        </Button>
      )}
    </>
  )
}

export default EvaluationButton
