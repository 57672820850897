import { Button } from '@mui/material'

import { Link } from 'react-router-dom'

interface IReflectionButtonProps {
  url: string
}
const ReflectionButton = ({ url }: IReflectionButtonProps) => {
  return (
    <Button
      component={Link}
      to={`${url}?f=reflection`}
      sx={{ mt: 1 }}
      size="small"
      variant="outlined"
    >
      Min reflektion
    </Button>
  )
}

export default ReflectionButton
