import { useState } from 'react'
import { Box, Link, Typography, useTheme } from '@mui/material'
import { DownloadForOffline } from '@mui/icons-material'
import { useEpiCustomDictionary } from '@trr/app-shell-data'

import { useGetJmtReportMutation } from '../../../utils/network/jmtApi'

const ComplementingReportSection = () => {
  const [downloading, setDownloading] = useState<boolean>(false)
  const [getPdf, { isLoading, error }] = useGetJmtReportMutation()

  const epi = useEpiCustomDictionary(['innehall'])

  const { palette } = useTheme()
  const downloadFile = () => {
    setDownloading(true)
    getPdf()
      .then(() => {
        setDownloading(false)
      })
      .catch((e: unknown) => {
        console.log(e)
      })
  }

  if (error) return null
  return (
    <Box>
      <Typography gutterBottom variant="h6" component="h2">
        {epi('complementingReports')}
      </Typography>
      <Link
        component={'button'}
        disabled={downloading || isLoading}
        onClick={downloadFile}
        sx={{
          '&[disabled]': {
            color: palette.action.disabled,
            pointerEvents: 'none',
          },
          display: 'flex',
          alignItems: 'center',
          mb: 2,
          '&:hover': {
            textDecoration: 'underline',
          },
        }}
      >
        <DownloadForOffline sx={{ mr: 1 }} /> {epi('downloadLabel')}
      </Link>
    </Box>
  )
}

export default ComplementingReportSection
