import { ForwardedRef, forwardRef } from 'react'
import { Box, Typography, useTheme } from '@mui/material'
import { useEpiCustomDictionary } from '@trr/app-shell-data'

import { LifeLineFeeling } from '../../../App/App.types'

import IconSelect, { ISelectOption } from './IconSelect'
import HappySmileyIcon from './HappySmileyIcon'
import SadSmileyIcon from './SadSmileyIcon'
import NeutralSmileyIcon from './NeutralSmileyIcon'

interface IMilestoneFeelingSelectorProps {
  value: LifeLineFeeling | undefined
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChange: (...event: any[]) => void
  error: string | undefined
}
const MilestoneFeelingSelector = (
  { value, onChange, error }: IMilestoneFeelingSelectorProps,
  ref: ForwardedRef<HTMLButtonElement>
) => {
  const epi = useEpiCustomDictionary(['innehall'])

  const { palette } = useTheme()
  const hasSelectedValue = value !== undefined

  const getIconStyles = (feeling: LifeLineFeeling) => ({
    my: -1,
    fontSize: '64px',
    color:
      value === feeling
        ? palette.primary.dark
        : hasSelectedValue
          ? palette.grey[700]
          : 'unset',
  })

  const feelingOptions: ISelectOption<LifeLineFeeling>[] = [
    {
      label: epi('negativeLabel'),
      value: LifeLineFeeling.Negative,
      icon: <SadSmileyIcon sx={getIconStyles(LifeLineFeeling.Negative)} />,
      testSelector: 'milestones-feeling-negative-button',
    },
    {
      label: epi('neutralLabel'),
      value: LifeLineFeeling.Neutral,
      icon: <NeutralSmileyIcon sx={getIconStyles(LifeLineFeeling.Neutral)} />,
      testSelector: 'milestones-feeling-neutral-button',
    },
    {
      label: epi('positiveLabel'),
      value: LifeLineFeeling.Positive,
      icon: <HappySmileyIcon sx={getIconStyles(LifeLineFeeling.Positive)} />,
      testSelector: 'milestones-feeling-positive-button',
    },
  ]

  return (
    <>
      <IconSelect
        hideLabel
        itemMaxWidth={'160px'}
        selectOptions={feelingOptions}
        selectedValue={value}
        onSelect={onChange}
        hasError={!!error}
        ref={ref}
      />

      {error && (
        <Box id="error-message" role="status">
          <Typography
            sx={{
              ml: '14px',
              color: palette.error.main,
              overflowWrap: 'break-word',
            }}
            variant="body2"
          >
            {error}
          </Typography>
        </Box>
      )}
    </>
  )
}

export default forwardRef(MilestoneFeelingSelector)
