import { Card, Typography, useTheme } from '@mui/material'

export interface IAmountLeftCardProps {
  number: number
  completed: boolean
  tilt?: number
}
export const AmountLeftCard = ({
  number,
  completed,
  tilt,
}: IAmountLeftCardProps) => {
  const { palette } = useTheme()
  return (
    <Card
      sx={{
        ml: '-15px',
        mb: tilt ? 0 : 2,
        transform: `rotate(${tilt?.toString() ?? '0'}deg)`,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '82px',
        height: '95px',
        borderColor: completed ? 'black' : 'unset',
        color: completed ? 'white' : palette.text.secondary,
        bgcolor: completed ? palette.primary.main : palette.grey[200],
      }}
    >
      <Typography variant="h4" component="p">
        {number}
      </Typography>
    </Card>
  )
}
